import bt_back_hover from '../assets/images/tournaments/button_back/bt_back_hover.png';
import bt_back_active from '../assets/images/tournaments/button_back/bt_back_pressed.png';
import bt_close_hover from '../assets/images/tournaments/button_close/bt_close_hover.png';
import bt_close_active from '../assets/images/tournaments/button_close/bt_close_pressed.png';
import bt_take_hover from '../assets/images/tournaments/button_take/bt_take_hover.png';
import bt_take_active from '../assets/images/tournaments/button_take/bt_take_pressed.png';
import bt_rules_hover from '../assets/images/tournaments/button_rules/bt_rules_hover.png';
import bt_rules_active from '../assets/images/tournaments/button_rules/bt_rules_pressed.png';
import bt_start_hover from '../assets/images/freeSpins/button_start/bt_start_hover.png';
import bt_start_active from '../assets/images/freeSpins/button_start/bt_start_pressed.png';

export const manifest = [
  bt_back_hover,
  bt_back_active,
  bt_close_hover,
  bt_close_active,
  bt_take_hover,
  bt_take_active,
  bt_rules_hover,
  bt_rules_active,
  bt_start_hover,
  bt_start_active,
];
