import EventEmitter from '../eventEmitter';
import {
  ControllerWin,
  ControllerBet,
  ControllerBalance,
  ControllerSpin,
  ControllerTotalWin,
  ControllerBetSelect,
  ControllerAutoPlay,
  ControllerInfo,
  ControllerMenu,
  ControllerFullScreen,
  ControllerSound,
  ControllerMaxBet,
  ControllerBetSelectorTable,
  ControllerHonesty,
} from '../controllers';
import eControllerTypes from '../controllers/eControllerTypes';

export default class BaseUi extends EventEmitter {
  constructor({ config, partnerConfig }) {
    super();

    this._partnerConfig = partnerConfig;
    this._config = {
      init_controls: true,
      ...config
    };

    this._eControllersClasses = {
      [this.controllerTypes.ECT_BALANCE]: ControllerBalance,
      [this.controllerTypes.ECT_BET]: ControllerBet,
      [this.controllerTypes.ECT_WIN]: ControllerWin,
      [this.controllerTypes.ECT_INFO]: ControllerInfo,
      [this.controllerTypes.ECT_MENU]: ControllerMenu,
      [this.controllerTypes.ECT_FULL_SCREEN]: ControllerFullScreen,
      [this.controllerTypes.ECT_TOTAL_WIN]: ControllerTotalWin,
      [this.controllerTypes.ECT_SPIN]: ControllerSpin,
      [this.controllerTypes.ECT_AUTO_SPIN]: ControllerAutoPlay,
      [this.controllerTypes.ECT_SOUND]: ControllerSound,
      [this.controllerTypes.ECT_MAX_BET]: ControllerMaxBet,
      [this.controllerTypes.ECT_BET_SELECT]: ControllerBetSelect,
      [this.controllerTypes.ECT_BET_SELECTOR_TABLE]: ControllerBetSelectorTable,
      [this.controllerTypes.ECT_HONESTY]: ControllerHonesty,
    };

    this._eControllersContainersIds = Object.values(this.controllerTypes).reduce((accum, type) => {
      if (accum.hasOwnProperty(type)) {
        throw new Error(`Controller type '${type}' should be uniq`);
      }
      accum[type] = `ui__${type}`;
      return accum;
    }, {});

    this._systemControllerTypes = new Set([this.controllerTypes.ECT_HONESTY]);
    this._excludedControllerTypes = new Set();
    this._supportedEventsList = new Set();
    this._controllers = null;

    this._checkConfig();
  }

  init() {
    this.root = document.createElement('div');
    this.root.setAttribute('id', 'root-ui');
    this.root.classList.add('ui-container');

    this.safeZone = document.createElement('div');
    this.safeZone.classList.add('ui-container__safe_zone');

    this.playerInterfaceContainer = document.createElement('div');
    this.playerInterfaceContainer.setAttribute('id', 'player-interface-container');
    this.playerInterfaceContainer.innerHTML = this._getMarkup();

    this.systemInterfaceContainer = document.createElement('div');
    this.systemInterfaceContainer.setAttribute('id', 'system-interface-container');
    this.systemInterfaceContainer.innerHTML = this._getSystemMarkup();

    this.safeZone.append(this.playerInterfaceContainer, this.systemInterfaceContainer);

    this.root.appendChild(this.safeZone);

    document.body.prepend(this.root);

    this._setupTheme();
    this._initControllers();
    this._addExtraControllersListeners();
  }

  on(event, callback) {
    if (!this._supportedEventsList.has(event)) return;
    super.on(event, callback)
  }

  setAvailableBets(bets) {
    const betsController = this.controllers[this.controllerTypes.ECT_BET];
    if (betsController) betsController.setBets(bets);

    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) {
      betsTableController.setBets(bets);

      const maxBetIndex = betsTableController.maxIndex;
      this.setRecentBets([0, Math.floor(maxBetIndex / 2), maxBetIndex]);

      this._addControllerListeners(this.controllerTypes.ECT_BET_SELECTOR_TABLE);
    }
  }

  setRecentBets(recentBets) {
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) betsTableController.setRecentBets(recentBets)
  }

  setCurrentBetIndex(betIndex) {
    const betsController = this.controllers[this.controllerTypes.ECT_BET];
    if (betsController) betsController.setCurrentBetIndex(betIndex);

    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    if (betsTableController) betsTableController.setCurrentBetIndex(betIndex)
  }

  setBalance(value) {
    const balanceController = this.controllers[this.controllerTypes.ECT_BALANCE];
    if (balanceController) balanceController.setValue(value);
  }

  setWin(value) {
    const winController = this.controllers[this.controllerTypes.ECT_WIN];
    if (winController) winController.setValue(value);
  }

  setLastWin(value) {
    const winController = this.controllers[this.controllerTypes.ECT_WIN];
    if (winController) winController.lastWin = value;
  }

  showTotalWin(value) {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.show(value);
  }

  hideTotalWin() {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.hide();
  }

  setTotalWin(value) {
    const totalWinContainer = this.controllers[this.controllerTypes.ECT_TOTAL_WIN];
    if (totalWinContainer) totalWinContainer.setValue(value);
  }

  showFreeSpins(count, text) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showFreespins(count, text);
  }

  hideFreeSpins() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.hideFreespins();
  }

  setFreeSpinsCount(count) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.freespinsCount = count;
  }

  toggleSpinButton(enabled) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (!spinController) return;

    if (enabled) {
      spinController.unblock(spinController.buttonsTypes.EBT_SPIN);
    } else {
      spinController.block(spinController.buttonsTypes.EBT_SPIN);
    }
  }

  toggleStopButton(enabled) {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (!spinController) return;

    if (enabled) {
      spinController.unblock(spinController.buttonsTypes.EBT_STOP);
    } else {
      spinController.block(spinController.buttonsTypes.EBT_STOP);
    }
  }

  toggleAutoPlayButton(enabled) {
    const autoPlayController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    if (!autoPlayController) return;

    if (enabled) {
      autoPlayController.unblock();
    } else {
      autoPlayController.block();
    }
  }

  toggleMenuButton(enabled) {
    const menuController = this.controllers[this.controllerTypes.ECT_MENU];
    if (!menuController) return;

    if (enabled) {
      menuController.unblock();
    } else {
      menuController.block();
    }
  }

  toggleInfoButton(enabled) {
    const infoController = this.controllers[this.controllerTypes.ECT_INFO];
    if (!infoController) return;

    if (enabled) {
      infoController.unblock();
    } else {
      infoController.block();
    }
  }

  toggleMaxBetButton(enabled) {
    const maxBetController = this.controllers[this.controllerTypes.ECT_MAX_BET];
    if (!maxBetController) return;

    if (enabled) {
      maxBetController.unblock();
    } else {
      maxBetController.block();
    }
  }

  toggleBetSelectors(enabled) {
    const betController = this.controllers[this.controllerTypes.ECT_BET];
    const betSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const betSelectorTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    [betController, betSelectController, betSelectorTableController].forEach(controller => {
      if (!controller) return;

      if (enabled) {
        controller.unblock();
      } else {
        controller.block();
      }
    });
  }

  showSpinButton() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showSpinButton();
  }

  showStopButton() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    if (spinController) spinController.showStopButton();
  }

  setAutoPlayButtonActive(value) {
    const autoPlayController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    if (autoPlayController) autoPlayController.toggleActive(value);
  }

  setSoundMuted(value) {
    const soundController = this.controllers[this.controllerTypes.ECT_SOUND];
    if (soundController) soundController.setMuted(value);
  };

  closePopups() {
    this.hideOverlay();
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    const betsSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
    if (betsTableController) betsTableController.hide();
    if (betsSelectController) betsSelectController.reset();
    if (honestyController) honestyController.hide();
  }

  //system api
  showHonestyControl(data) {
    const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
    if (honestyController) {
      //set data to controller
      //honestyController.setData(data)
      honestyController.show();
    }
  }

  hide() {
    this.closePopups();
    this.playerInterfaceContainer.classList.add('hidden');
  }

  setOpacity(value) {
    this.playerInterfaceContainer.style.opacity = typeof(+value) === 'number' ? value : 1;
  }

  show() {
    this.playerInterfaceContainer.classList.remove('hidden');
  }

  showOverlay() {
    this.root.classList.add('overlay-visible');
  }

  hideOverlay() {
    this.root.classList.remove('overlay-visible');
  }

  remove() {
    this.removeAllListeners();
    for (let controller of Object.values(this.controllers)) {
      controller.removeAllListeners();
      controller.beforeRemove();
    }
    this.root.remove();
  }

  _checkConfig() {
    if (!this._config.init_controls) {

      const types = Object.values(this.controllerTypes);
      for (const type of types) {
        if (this._systemControllerTypes.has(type)) continue;
        this._excludedControllerTypes.add(type)
      }

      this._getMarkup = () => '';
      return;
    }

    if (this._partnerConfig.disableAutoplay) {
      this._excludedControllerTypes.add(this.controllerTypes.ECT_AUTO_SPIN)
    }
  }

  _initControllers() {
    this._controllers = {};
    for (let key in this._eControllersContainersIds) {
      if (this._excludedControllerTypes.has(key) && !this._systemControllerTypes.has(key)) {
        continue;
      }

      const id = this._eControllersContainersIds[key];
      const container = document.getElementById(id);
      if (!container) throw new Error(`Can't find container by given id: '${id}'`);

      const controller = this._eControllersClasses[key];
      this._controllers[key] = new controller(container);

      if (key === this.controllerTypes.ECT_BET_SELECTOR_TABLE) continue; //wait for available bets for events initialization
      this._addControllerListeners(key);
    }
  }

  _addControllerListeners(type) {
    if (!this._controllers[type].events) return;

    for (let event of Object.values(this._controllers[type].events[type])) {
      this._supportedEventsList.add(event);
      this._controllers[type].on(event, (params) => {
        console.log(event);
        this.emit(event, params)
      });
    }
  }

  _addExtraControllersListeners() {
    const betsSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
    const events = this.events;

    if (betsSelectController) {
      betsSelectController.on(events[this.controllerTypes.ECT_BET_SELECT].SHOW_BETS_CLICK, () => {
        this.showOverlay();
        if (betsTableController) betsTableController.show();
      });

      betsSelectController.on(events[this.controllerTypes.ECT_BET_SELECT].HIDE_BETS_CLICK, () => {
        this.hideOverlay();
        if (betsTableController) betsTableController.hide();
      });
    }

    if (honestyController) {
      honestyController.on(events[this.controllerTypes.ECT_HONESTY].CLOSE_CLICK, () => {
        this.show();
      });
    }
  }

  _setupTheme() {
    if (this._config && this._config.colors) {
      for (let key in this._config.colors) {
        for (let state in this._config.colors[key]) {
          document.documentElement.style.setProperty(`--color-${key}--${state}`, this._config.colors[key][state]);
        }
      }
    }
  }

  _getMarkup() {
    throw new Error(`You must override 'getMarkup' method inside derived class`);
  }

  _getSystemMarkup() {
    throw new Error(`You must override 'getSystemMarkup' method inside derived class`);
  }

  get spinButtonEnabled() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    return spinController && !spinController.isBlocked(spinController.buttonsTypes.EBT_SPIN);
  }

  get stopButtonEnabled() {
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    return spinController && !spinController.isBlocked(spinController.buttonsTypes.EBT_STOP);
  }

  get events() {
    const events = {};

    if (this._controllers) {
      for (let controller of Object.values(this._controllers)) {
        Object.assign(events, controller.events);
      }
    }

    return events;
  }

  get controllers() {
    return this._controllers;
  }

  get controllersContainersIds() {
    return this._eControllersContainersIds;
  }

  get controllerTypes() {
    return eControllerTypes;
  }

  get supportedEventsList() {
    return this._supportedEventsList;
  }

  set scaleData(data) {
    this._scaleData = data;
    const uiScale = Math.min(this._scaleData.innerWidth / window.OPWrapperService.ScaleManager.safeZone.width, this._scaleData.innerHeight / window.OPWrapperService.ScaleManager.safeZone.height);

    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    const width = 100 * (1 / uiScale);
    const left = -(width - 100) / 2;
    const height = 100 * (1 / uiScale);
    const top = -(height - 100) / 2;

    const safeWidth = this._scaleData.stageX > 0 ? (this._scaleData.stageX * 2) / this._scaleData.scale : 0;
    const safeHeight = this._scaleData.stageY < 0 ? (this._scaleData.stageY * 2) / this._scaleData.scale : 0;
    const safeLeftOffset = this._scaleData.stageX > 0 ? this._scaleData.stageX / this._scaleData.scale : 0;
    const safeTopOffset = this._scaleData.stageY < 0 ? this._scaleData.stageY / this._scaleData.scale : 0;

    let marginX = window.OPWrapperService.ScaleManager.safeZone.left;
    let marginY = window.OPWrapperService.ScaleManager.safeZone.top;

    if (this._scaleData.stageX < 0) {
      marginX += this._scaleData.stageX / this._scaleData.scale;
    }
    if (this._scaleData.stageY > 0) {
      marginY += this._scaleData.stageY / this._scaleData.scale;
    }

    this.safeZone.style.marginRight = `${marginX}px`;
    this.safeZone.style.marginLeft = `${marginX}px`;

    this.safeZone.style.marginTop = `${marginY}px`;
    this.safeZone.style.marginBottom = `${marginY}px`;
    this.safeZone.style.height = `calc(100% - ${marginY * 2}px)`;

    Object.assign(this.root.style, {
      transform: `scale(${uiScale}) translateZ(0)`,
      width: `calc(${width}% - ${safeWidth}px)`,
      height: `calc(${height}% - ${safeHeight}px)`,
      left: `calc(${left}% + ${safeLeftOffset}px)`,
      top: `calc(${top}% + ${safeTopOffset}px)`,
    });
  }
}
