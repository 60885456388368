import BaseController from './base';
import eControllerTypes from './eControllerTypes';

export default class ControllerBetSelect extends BaseController {
  constructor(container) {
    super(eControllerTypes.ECT_BET_SELECT);
    this._eButtonsTypes = {
      EBT_SHOW_BETS: 'show_bets_button',
      EBT_HIDE_BETS: 'hide_bets_button',
    };
    this._eEventTypes =  {
      SHOW_BETS_CLICK: this.getEventName(this._eButtonsTypes.EBT_SHOW_BETS),
      HIDE_BETS_CLICK: this.getEventName(this._eButtonsTypes.EBT_HIDE_BETS),
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_SHOW_BETS]: this._showCloseButton.bind(this),
      [this._eButtonsTypes.EBT_HIDE_BETS]: this._showOpenButton.bind(this),
    };
    this._eElementsTypes = {
      EET_SHOW_BETS_BUTTON: this._eButtonsTypes.EBT_SHOW_BETS,
      EET_HIDE_BETS_BUTTON: this._eButtonsTypes.EBT_HIDE_BETS,
    };

    this.init(container);
    this._showOpenButton();
  }

  reset() {
    this._showOpenButton();
  }

  _showOpenButton() {
    this.interactiveElements[this._eElementsTypes.EET_SHOW_BETS_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_HIDE_BETS_BUTTON].classList.add('hidden');
  }

  _showCloseButton() {
    this.interactiveElements[this._eElementsTypes.EET_SHOW_BETS_BUTTON].classList.add('hidden');
    this.interactiveElements[this._eElementsTypes.EET_HIDE_BETS_BUTTON].classList.remove('hidden');
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SHOW_BETS_BUTTON]}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M49.3327 33.7332V43.5998C49.3327 51.9198 41.5727 58.6665 31.9993 58.6665C22.426 58.6665 14.666 51.9198 14.666 43.5998V33.7332C14.666 42.0532 22.426 47.9998 31.9993 47.9998C41.5727 47.9998 49.3327 42.0532 49.3327 33.7332Z"
                  stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
              <path
                  d="M49.3327 20.3999C49.3327 22.8266 48.666 25.0666 47.4927 26.9866C44.6393 31.6799 38.7727 34.6666 31.9993 34.6666C25.226 34.6666 19.3594 31.6799 16.506 26.9866C15.3327 25.0666 14.666 22.8266 14.666 20.3999C14.666 16.2399 16.6127 12.4799 19.7327 9.75992C22.8793 7.01325 27.1993 5.33325 31.9993 5.33325C36.7993 5.33325 41.1194 7.01326 44.266 9.73326C47.386 12.4799 49.3327 16.2399 49.3327 20.3999Z"
                  stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
              <path
                  d="M49.3327 20.3999V33.7333C49.3327 42.0533 41.5727 47.9999 31.9993 47.9999C22.426 47.9999 14.666 42.0533 14.666 33.7333V20.3999C14.666 12.0799 22.426 5.33325 31.9993 5.33325C36.7993 5.33325 41.1194 7.01326 44.266 9.73326C47.386 12.4799 49.3327 16.2399 49.3327 20.3999Z"
                  stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_HIDE_BETS_BUTTON]} data-color="cancel">
            <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.1602 35.839L35.8385 22.1606" stroke="white" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M35.8385 35.839L22.1602 22.1606" stroke="white" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.7487 53.1668H36.2487C48.332 53.1668 53.1654 48.3335 53.1654 36.2502V21.7502C53.1654 9.66683 48.332 4.8335 36.2487 4.8335H21.7487C9.66536 4.8335 4.83203 9.66683 4.83203 21.7502V36.2502C4.83203 48.3335 9.66536 53.1668 21.7487 53.1668Z" stroke="white" stroke-width="3.625" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>`
  }
}
