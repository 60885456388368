import BaseFreeBetsItem from './baseFreeBetsItem';
import LocalizationManager from '../../i18n/LocalizationManager';
import { format } from '../../utils/formatCurrency';

export default class FreeBetsBottomBar extends BaseFreeBetsItem {
  constructor(container) {
    super(container);
    this.freeBetsElement = this.view.querySelector('.freeBetsBottomBar__currentBetsAmount');
    this.freeBetsTotalElement = this.view.querySelector('.freeBetsBottomBar__betsCountTotalBetsAmount');
    this.betElement = this.view.querySelector('.freeBetsBottomBar__betAmount');
    this.totalWinElement = this.view.querySelector('.freeBetsBottomBar__totalWinAmount');
  }

  update(data) {
    this.data = data;
    this.freeBetsTotalElement.textContent = data.freeBetsQuantity;
    this.setBet(data);
    this.setFreeBets(data.freeBets);
    this.setTotalWin(data.totalWin);
  }

  setBet(data) {
    this.data = data;
    this.betElement.textContent = `${format(data.bet, data.decimals)} ${data.currency}`;
  }

  setFreeBets(value) {
    this.freeBetsElement.textContent = value;
  }

  setTotalWin(value) {
    this.totalWinElement.textContent = `${format(value, this.data.decimals)} ${this.data.currency}`;
  }

  createMarkup() {
    return `
    <div id="freeBetsBottomBar">
      <div class="freeBetsBottomBar__container">
        <div class="freeBetsBottomBar__betsCount freeBetsBottomBar__item">
          <span class="freeBetsBottomBar__betsCountLeftText">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.bottomBar.betsCountLeftText')}</span>
          <span class="freeBetsBottomBar__currentBetsAmount">1</span>
          <span class="freeBetsBottomBar__betsCountMidleText">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.bottomBar.betsCountMidleText')}</span>
          <span class="freeBetsBottomBar__betsCountTotalBetsAmount">20</span>
        </div>
        <div class="freeBetsBottomBar__bet freeBetsBottomBar__item">
          <span class="freeBetsBottomBar__betText">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.bottomBar.bet')}</span>
          <span class="freeBetsBottomBar__betAmount">5 usd</span>
        </div>
        <div class="freeBetsBottomBar__totalWin freeBetsBottomBar__item">
          <span class="freeBetsBottomBar__totalWinText">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.bottomBar.totalWin')}</span>
          <span class="freeBetsBottomBar__totalWinAmount">0 usd</span>
        </div>
      </div>
    </div>
    `
  }
}
