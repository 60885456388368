export default {
  EBT_HOME: 'home',
  EBT_RULES: 'rules',
  EBT_SOUND: 'sound',
  EBT_INFO: 'info',
  EBT_SETTINGS: 'settings',
  EBT_PAYTABLE: 'paytable',
  EBT_CLOSE: 'close',
  EBT_LEADERBOARD: 'leaderboard',
  EBT_GAME_LIMITS: 'gameLimits',
}
