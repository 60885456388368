import LocalizationManager from '../../i18n/LocalizationManager';
import star from '../../assets/images/tournaments/list_star.png';
import { tournamentPopupTypes } from '../controller/popupTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../utils/constants';

export default class InviteTournamentPopup {
  constructor(container, config, showRulesCallback, hideCallback) {

    this.container = container;
    this.title = config.tournamentTitle;
    this.giftsCount = config.giftsCount;
    this.endTime = config.endTime.split('-');
    this.prize = config.prizeValue;
    this.textList = config.textList;
    this._createMarkup();

    this.view = document.getElementById('tournament_invite_popup');
    this.titlePseudoEl = document.getElementById('tournament_invite_title_pseudo');
    this.titleEl = document.getElementById('tournament_invite_title');
    this.giftsEl = document.getElementById('tournament_invite_gifts');
    this.endsInEl = document.getElementById('tournament_invite_ends_in');
    this.timeLeftEl = document.getElementById('tournament_invite_time_left');
    this.prizeFundEl = document.getElementById('tournament_invite_prize_fund');
    this.rulesButtonEl = document.getElementById('invite_tournament_rules_button_inner');
    this.termsEl = document.getElementById('tournament_invite_terms');
    this.participateEl = document.getElementById('invite_tournament_take_button_inner');
    this.listElArray = document.querySelectorAll('[id=invite_tournament_text_list_el]');

    LocalizationManager.addLocalizationChangedCallback(this._updateLocalization.bind(this));
    this._updateLocalization();

    document.getElementById('invite_tournament_take_button').addEventListener('click', () => hideCallback(tournamentPopupTypes.INVITE));
    document.getElementById('invite_tournament_rules_button').addEventListener('click', showRulesCallback);
  }

  show() {
    this.view.classList.remove('tournament_hidden');
    this.container.classList.remove('tournament_hidden');
  };

  hide() {
    this.view.classList.add('tournament_hidden');
    this.container.classList.add('tournament_hidden');
  };

  _updateLocalization() {
    const titleLocalization = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.title`, [this.title]);
    const timeLeftLocalization = LocalizationManager.getLocalizedText(
      `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.endTime`, [this.endTime[0], this.endTime[1], this.endTime[2], this.endTime[3]]);
    const giftsLocalization = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.invite.gifts`);
    const prizeFundLocalization = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.prizeFund`);
    const participateLocalization = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.invite.participate`);

    this.titlePseudoEl.setAttribute('title', titleLocalization);
    this.titleEl.innerText = titleLocalization;
    this.giftsEl.setAttribute('title', giftsLocalization);
    this.giftsEl.innerText = giftsLocalization;
    this.endsInEl.innerText = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.endsIn`);
    this.timeLeftEl.setAttribute('title', timeLeftLocalization);
    this.timeLeftEl.innerText = timeLeftLocalization;
    this.prizeFundEl.setAttribute('title', prizeFundLocalization);
    this.prizeFundEl.innerText = prizeFundLocalization;
    this.rulesButtonEl.innerText = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.rules`);
    this.termsEl.innerText = '';
    this.termsEl.insertAdjacentHTML('beforeend', LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.invite.terms`));
    this.participateEl.setAttribute('title', participateLocalization);
    this.participateEl.innerText = participateLocalization;
    this.listElArray.forEach((el, i) =>
      el.innerText = LocalizationManager.getLocalizedText(this.textList[i], [this.title]));
  }

  _createMarkup() {
    this.container.insertAdjacentHTML('beforeend',
      `<div id="tournament_invite_popup" class="tournament_invite_popup tournament_hidden">` +
      `<div class="tournament_invite_popup_title">` +
      `<span id="tournament_invite_title_pseudo" title="Турнир: ${this.title}">` +
      `<span id="tournament_invite_title">Турнир: ${this.title}</span>` +
      `</span>` +
      `</div>` +
      `<div class="tournament_invite_popup_body">` +
      `<div class="tournament_invite_popup_gifts">` +
      `<span title="${this.giftsCount}">${this.giftsCount}</span>` +
      `<span id="tournament_invite_gifts" title="ПОДАРКОВ">ПОДАРКОВ</span>` +
      `</div>` +

      `<div class="tournament_invite_popup_time_left">` +
      `<span id="tournament_invite_ends_in" class="tournament_invite_popup_time_left_end">ЗАКАНЧИВАЕТСЯ ЧЕРЕЗ:</span>` +
      `<span id="tournament_invite_time_left" class="tournament_invite_popup_time_left_end_time"
      title="${this.endTime[0]}дн ${this.endTime[1]}ч ${this.endTime[2]}мин ${this.endTime[3]}с">
      ${this.endTime[0]}дн ${this.endTime[1]}ч ${this.endTime[2]}мин ${this.endTime[3]}с
      </span>` +
      `</div>` +

      `<span id="tournament_invite_prize_fund" class="tournament_invite_popup_body_head" title="ПРИЗОВОЙ ФОНД">
        ПРИЗОВОЙ ФОНД
        </span>` +
      `<div class="tournament_invite_popup_body_head_value">` +
      `<span title="${this.prize}">` +
      `<span>
        ${this.prize}
        </span>` +
      `</span>` +
      `</div>` +

      `<div class="tournament_invite_popup_body_list">

        ${this.textList.map(key => `<span class="tournament_invite_popup_body_list_item">` +
        `<img src="${star}" alt="" />` +
        `<span id="invite_tournament_text_list_el">${key}</span></span>`).join('')}
      
        </div>` +

      `<div id="invite_tournament_rules_button" class="tournament_invite_popup_button_rules">` +
      `<span id="invite_tournament_rules_button_inner">
        ПРАВИЛА
        </span>` +
      `</div>` +

      `<div class="tournament_invite_popup_terms">` +
      `<div class="tournament_head_top_line"></div>` +
      `<span id="tournament_invite_terms" class="tournament_invite_popup_terms_text">` +
      `</span>` +
      `<div class="tournament_head_bottom_line"></div>` +
      `</div>` +

      `<div id="invite_tournament_take_button" class="tournament_invite_popup_take_button">` +
      `<span id="invite_tournament_take_button_inner" title="ПРИНЯТЬ УЧАСТИЕ">
        ПРИНЯТЬ УЧАСТИЕ
        </span>` +
      `</div>` +
      `</div>`
    );
  };
}
