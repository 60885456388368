export default {
  ECT_BALANCE: 'controller_balance',
  ECT_BET: 'controller_bet',
  ECT_WIN: 'controller_win',
  ECT_INFO: 'controller_info',
  ECT_MENU: 'controller_menu',
  ECT_FULL_SCREEN: 'controller_full_screen',
  ECT_TOTAL_WIN: 'controller_total_win',
  ECT_SPIN: 'controller_spin',
  ECT_AUTO_SPIN: 'controller_auto_spin',
  ECT_SOUND: 'controller_sound',
  ECT_MAX_BET: 'controller_max_bet',
  ECT_BET_SELECT: 'controller_bet_selector',
  ECT_BET_SELECTOR_TABLE: 'controller_selector_table',
  ECT_HONESTY: 'controller_honesty',
}
