import fullscreenIcon from '../assets/images/fullscreen/fullscreen.svg'
import fullscreenIconWithStroke from '../assets/images/fullscreen/fullscreen__stroke.svg'
import fullscreenExitIcon from '../assets/images/fullscreen/fullscreen-exit.svg'
import fullscreenExitIconWithStroke from '../assets/images/fullscreen/fullscreen-exit__stroke.svg'
import UserAgentDetector from '../utils/UserAgentDetector';

export default class Fullscreen {
  constructor(container) {
    container.insertAdjacentHTML('beforeend',
      `<div id="OPFullscreenButton" class="hide">
              <img id="OPFullscreenButton_image" src="${fullscreenIcon}">
            </div>`
      );
    this.button = document.getElementById('OPFullscreenButton');
    this.buttonImage = document.getElementById('OPFullscreenButton_image');
    this.button.addEventListener('touchstart', this._onTouch.bind(this))
    this.button.addEventListener('touchend', (e) => {
      e.stopPropagation();
      e.preventDefault();
    })
   this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this._setIcon = this._setIcon.bind(this)
  }

  get isFullscreen() {
    return document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullScreenElement
      || document.msFullscreenElement;
  }

  showButtonIfAndroid(withStroke) {
    if (UserAgentDetector.isMobile.android.device) {
      this.showButton(withStroke);
    }
  }

  showButton(withStroke) {
    if (!document.fullscreenEnabled) {
      this.checkFullscreenAllows();
      return;
    }
    if (this.buttonEnabled) return;
    this.buttonEnabled = true
    this.fullscreenIcon = withStroke ? fullscreenIconWithStroke : fullscreenIcon;
    this.fullscreenExitIcon = withStroke ? fullscreenExitIconWithStroke : fullscreenExitIcon;
    this.button.classList.remove('hide');
    window.addEventListener('fullscreenchange', this._setIcon);
    this._setIcon()
  }

  hideButton() {
    if (!this.buttonEnabled) return;
    this.buttonEnabled = false
    this.button.classList.add('hide');
    window.removeEventListener('fullscreenchange', this._setIcon);
  }

  setButtonPosition({ left, top, right, bottom, x, y }) {
    this.button.style.left = x ? `${x}px` : left ? left : null;
    this.button.style.top = y ? `${y}px` : top ? top : null;
    this.button.style.right = right ? right : null;
    this.button.style.bottom = bottom ? bottom : null;
  }

  setButtonSize(width, height) {
    this.buttonImage.style.width = typeof(width) === 'number' ? `${width}px`: width;
    this.buttonImage.style.height = typeof(height) === 'number' ? `${height}px`: height;
  }

  _setTransparentTimeout() {
    clearTimeout(this.timeout)
    this.button.classList.remove('transparent');
    this.timeout = setTimeout(() => this.button.classList.add('transparent'), 30000)
  }

  _setIcon() {
    this.buttonImage.src = this.isFullscreen ? this.fullscreenExitIcon : this.fullscreenIcon;
    this._setTransparentTimeout();
  }

  toggleFullscreen() {
    this.isFullscreen ? this._closeFullscreen() : this._openFullscreen();
  }

  checkFullscreenAllows() {
    if (window.OPWrapperService.sideBar.isIframe() && document.featurePolicy && !document.featurePolicy.allowsFeature("fullscreen")) {
      console.warn("Game can't use fullscreen, please add allow=\"fullscreen\" attribute to iframe element");
    }
  }

  _onTouch(e) {
    window.OPWrapperService.initConfig.onButtonClick();
    e.preventDefault();
    e.stopPropagation();
    this.toggleFullscreen();
  }

  _openFullscreen() {
    let element = document.documentElement;
    const requestFullscreen = element.requestFullscreen
      || element.mozRequestFullScreen
      || element.webkitRequestFullscreen
      || element.msRequestFullscreen;
    setTimeout(() =>{
      requestFullscreen && document.fullscreenEnabled && requestFullscreen.call(element, { navigationUI: "hide" })
    }, 200)
  }

  _closeFullscreen() {
    const exitFullscreen = document.exitFullscreen
      || document.mozCancelFullScreen
      || document.webkitExitFullscreen
      || document.msExitFullscreen;
    exitFullscreen && exitFullscreen.call(document)
  }
}
