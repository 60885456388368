export default class Event {

  constructor() {
    this._listeners = [];
  }

  add(listener) {
    if (!listener) {
      console.error('listener is undefined');
      console.trace();
      return;
    }
    this._listeners.push(listener);
    return listener;
  };

  remove(listener) {
    this._listeners.splice(this._listeners.indexOf(listener), 1);
  };

  call(...args) {
    for (let i = 0; i < this._listeners.length; i++) {
      this._listeners[i](...args);
    }
  }
};
