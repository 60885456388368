import LocalizationManager from '../i18n/LocalizationManager';

export default class ButtonSound {
  constructor(data) {
    this.element = document.createElement('div');
    this.onCLickCallback = data.onClick;

    this.element.classList.add('buttonContainer');
    this.element.addEventListener('click', this._onClick.bind(this));
    this.element.insertAdjacentHTML('afterBegin',
      `<div class="icon ${data.styles && data.styles.join(' ')}"></div>` +
      `<div class="text">${LocalizationManager.getLocalizedText(`OPWrapperService.buttons.${data.type}`)}</div>` +
      `<div class="separator"></div>`
    );

    this.icon = this.element.getElementsByClassName('icon')[0];
  }

  set isMutedStyle(boolean) {
    if (boolean) {
      this.icon.classList.add('soundOff');
      this.icon.classList.remove('soundOn');
    } else {
      this.icon.classList.add('soundOn');
      this.icon.classList.remove('soundOff');
    }
  }

  _onClick() {
    window.OPWrapperService.initConfig.onButtonClick();
    let result = this.onCLickCallback();
      this.isMutedStyle = !result;
  }
}
