import './index.css';
import './utils/fpsMonitor';
import MessageWindow from './messageWindow/messageWindow';
import SideBar from './sideBar/sideBar';
import BetSelector from './betSelector/betSelector';
import EventManager from './events/manager';
import { EMPostMessageTypes, EMReceiveMessageTypes } from './platforms/everyMatrix/constants';
import EveryMatrixMessageManager from './platforms/everyMatrix/EveryMatrixMessageManager';
import { postMessageTypes, receiveMessageTypes } from './messageManager/constants';
import MessageManager from './messageManager/MessageManager';
import PartnerConfig from './platforms/PartnerConfig';
import InfoPanel from './infoPanel';
import { getUrlParam } from './utils/url';
import { ManagerRest } from './api/managerRest';
import TournamentController from './tournaments/controller/tournamentController';
import { preloadAssets } from './utils/preloadAssets';
import { manifest } from './assets/manifest';
import LocalizationManager from './i18n/LocalizationManager';
import { errorTypes } from './utils/errorTypes';
import FreeBetsController from './freeBets/freeBetsController';
import GameLimitsWindow from './gameLimitsWindow/gameLimitsWindow';
import ControllerUi from './ui';
import ControllerRulesWindow from './rules/ControllerRules';
import UserAgentDetector from './utils/UserAgentDetector';
import ScaleManager from './scaleManager/ScaleManager';
import eEventTypes from './events/eEventTypes';
import Fullscreen from './fullscreen/fullscreen';
import RealityCheck from './realityCheck';
import ControllerStatistic from './statistic/ControllerStatistic';
import './productionFeatures/productionFeatures'

class OPWrapperService {
  constructor(config) {
    this.initConfig = {
      onButtonClick: () => {
      }, ...config
    };
    this._blockViewportScale();
    this.config = new PartnerConfig();
    this.managerRest = new ManagerRest();
    // this._init();
    this.openedWindows = [];
    this.errors = errorTypes;

    window.OPWrapperService = this;

    const id = 'opMainContainer';
    document.body.insertAdjacentHTML('beforeend', `<div id="${id}" class="opWrapper"></div>`);
    this.view = document.getElementById(id);

    LocalizationManager.init(config.localizations, config.lang, config.localizationBundle, config.fallbackLang);

    this.eventManager = EventManager;
    this.eventManager.types = eEventTypes;

    if (getUrlParam('pid') === '322') {
      this.eventManager.receiveMessageTypes = EMReceiveMessageTypes;
      this.eventManager.postMessageTypes = EMPostMessageTypes;
      new EveryMatrixMessageManager();
    } else {
      this.eventManager.receiveMessageTypes = receiveMessageTypes;
      this.eventManager.postMessageTypes = postMessageTypes;
      new MessageManager();
    }

    this.messageWindow = new MessageWindow(this.view);
    this.sideBar = new SideBar(this.view, { buttons: config.buttons || [] }, this.toggleOpenedWindow.bind(this), this.openedWindows.length);
    this.Fullscreen = new Fullscreen(this.view);
    this.ControllerStatistic = new ControllerStatistic();

    this.ScaleManager = ScaleManager;
    this.UserAgentDetector = UserAgentDetector;

    window.addEventListener('online', this._handleInternetOfflineEvent.bind(this));
    window.addEventListener('offline', this._handleInternetOfflineEvent.bind(this));
  }

  async init({ partnerConfig, currencyInfo, language }) {
    this.config.updateData(partnerConfig, language);
    this._currencyInfo = Object.freeze(currencyInfo);
    this.initConfig.lang = this.config.lang || this.initConfig.lang;

    await LocalizationManager.changeLocalization(this.initConfig.lang);
    this.initControllers();

    if (this.config.showRulesOnStart) this.rulesWindow.show();

    if (this.config.session.checkStatus) {
      document.addEventListener('click', this.startCheckSessionStatusTimer.bind(this));
      document.addEventListener('touchstart', this.startCheckSessionStatusTimer.bind(this));
      this.startCheckSessionStatusTimer()
    }
  }

  initControllers() {
    this.infoPanel = new InfoPanel(this.initConfig, this.config);
    this.gameLimitsWindow = new GameLimitsWindow(this.view);
    this.realityCheck = new RealityCheck(this.view);
    this.betSelector = new BetSelector(this.view);
    this.freeBetsController = new FreeBetsController(this.view);
    this.rulesWindow = new ControllerRulesWindow(this.view, {
      bundle: this.initConfig.bundle,
      lang: this.initConfig.lang
    });

    this.ControllerStatistic.init(this.config, this.initConfig);
    this.ControllerStatistic.onUpdate.add(this.infoPanel.onStatisticUpdate);
    this.ControllerStatistic.onUpdate.add(this.realityCheck.onStatisticUpdate);
    this.sideBar.init();
  }

  initUi(config) {
    if (this.ControllerUi) {
      console.error('UI already created');
      return this.ControllerUi;
    }

    this.ControllerUi = new ControllerUi({
      config,
      partnerConfig: this.config
    }).init({ scaleData: this.ScaleManager.currentData });
    return this.ControllerUi;
  }

  toggleOpenedWindow(window) {
    if (this.openedWindows.includes(window)) {
      return this.openedWindows = this.openedWindows.filter(el => el !== window);
    }
    this.openedWindows.push(window);
  }

  showTournamentPopup(popupType) {
    if (!this.tournamentController) return;
    this.tournamentController.show(popupType);
  }

  showMessage(title, description, showOkButton, needReload) {
    this.messageWindow.show(title, description, showOkButton, needReload);
  }

  showError(code, messageFallback = null) {
    this.messageWindow.showError(code, messageFallback);
  }

  showSideBar() {
    this.sideBar.show();
  }

  toggleSidebar() {
    this.sideBar.toggle();
  }

  initBetSelector(bets, currency, activeBetIndex) {
    this.betSelector.init(bets, currency, activeBetIndex);
  }

  toggleBetSelector() {
    this.betSelector.toggle();
  }

  async changeLocalization(lang) {
    await LocalizationManager.changeLocalization(lang);
    this.sideBar.updateButtons();
  }

  async _initTournament() {
    //TODO:
    this.wrapperInfo = await this.managerRest.getWrapperInfo();
    if (!this.wrapperInfo) return;

    LocalizationManager.changeLocalization(this.wrapperInfo.lang);
    this.tournamentController = new TournamentController(this.view, this.wrapperInfo.tournament, this.toggleOpenedWindow.bind(this), this.openedWindows.length);
    this._preloadAssets();
  }

  showStartPopups() {
    this.ControllerStatistic.downloadStopped();
    this.gameLimitsWindow.tryShowGameLimits(this.config);
    this.realityCheck.init(this.config);
  }

  _handleInternetOfflineEvent() {
    if (navigator.onLine) return;
    this.showError(this.errors.NO_CONNECTION.CODE);
  }

  _preloadAssets() {
    preloadAssets(manifest);
  }

  startCheckSessionStatusTimer() {
    clearInterval(this._checkStatusTimer)
    if (this.config.session.checkStatusInterval) {
      this._startedTime = Date.now();
      this._checkStatusTimer = setInterval(() => this.managerRest.checkSessionStatus((Date.now() - this._startedTime) / 1000), this.config.session.checkStatusInterval * 1000)
    }
  }

  _blockViewportScale() {
    window.addEventListener('wheel', (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {
      'passive': false
    });
  }

  get gameVersion() {
    return this.sideBar.gameVersion;
  }

  set gameVersion(value) {
    this.sideBar.gameVersion = value;
  }

  get localizations() {
    return LocalizationManager;
  }

  set keyDownEventsBlocked(value) {
    this._keyDownEventsBlocked = value;
  }

  get keyDownEventsBlocked() {
    return this._keyDownEventsBlocked;
  }

  get currencyInfo() {
    return this._currencyInfo;
  }
}

export default OPWrapperService;
