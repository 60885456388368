import InviteTournamentPopup from '../modals/inviteTournament';
import ScoreTournamentPopup from '../modals/scoreTournament';
import RulesTournamentPopup from '../modals/rulesTournament';
import { tournamentPopupTypes } from './popupTypes';

// const tournamentInfo = {
//   rules: ['tournaments.rules.offer', 'tournaments.rules.about', 'tournaments.rules.funds', 'tournaments.rules.principle'],     //хардкод
//   config: {
//     tournamentTitle: 'Авианосец',     // Авианосец
//     giftsCount: 10,       //кол-во призов
//     endTime: { d: '01', h: '02', m: '03', s: '04' },     //или строка 'dd-hh-mm-ss' (как тебе удобно)
//     prizeValue: '0.00000001 BTC',      // 1.23423254 BTC,
//     textList: ['tournaments.invite.welcome', 'tournaments.invite.about', 'tournaments.invite.playAndWin'],         // хардкод
//     topPlacesCount: 1,      // кол-во призовых мест
//     playersResults: [{ position: 1, name: 'yaros', score: 2113 }, { position: 2, name: 'gos', score: 13 }],
//     player: { position: 2, name: 'sfs', score: 24234342 }
//   }
// };

export default class TournamentController {
  // constructor(container, tournamentInfo) {
  constructor(container, info, toggleOpenedWindow, hasOpenedWindows) {
    const id = 'tournament_wrapper';
    container.insertAdjacentHTML('beforeend',
      `<div id="${id}" class="tournamentWrapper tournament_hidden"></div>`);
    this.view = document.getElementById(id);
    this.lastPopup = '';
    this.container = container;
    this.toggleOpened = toggleOpenedWindow;
    this.hasOpened = hasOpenedWindows;

    this[tournamentPopupTypes.INVITE] = new InviteTournamentPopup(this.view, info.config, this.showRules.bind(this), this.hide.bind(this));
    this[tournamentPopupTypes.SCORE] = new ScoreTournamentPopup(this.view, info.config, this.showRules.bind(this), this.hide.bind(this));
    this[tournamentPopupTypes.RULES] = new RulesTournamentPopup(this.view, info.rules, this.goBack.bind(this), this.hide.bind(this));

    if (!info.visited) {
      this.show(tournamentPopupTypes.SCORE);
    }
  }

  show(popupType) {
    this.container.classList.add('enable');
    this[popupType].show();
    this.lastPopup = popupType === tournamentPopupTypes.RULES ? this.lastPopup : popupType;
    this.toggleOpened(popupType);
  }

  hide(popupType) {
    this[popupType].hide();
    this.toggleOpened(popupType);
    !this.hasOpened && this.container.classList.remove('enable');
  }

  showRules() {
    this.hide(this.lastPopup);
    this.show(tournamentPopupTypes.RULES);
  }

  goBack() {
    this.hide(tournamentPopupTypes.RULES);
    if (this.lastPopup) {
      let popupType = this.lastPopup;
      this.lastPopup = '';
      return this.show(popupType);
    }
    !this.hasOpened && this.container.classList.remove('enable');
  }
}
