import BaseController from './base';
import eControllerTypes from './eControllerTypes';

export default class ControllerSpin extends BaseController {
  constructor(container) {
    super(eControllerTypes.ECT_SPIN);
    this._eButtonsTypes = {
      EBT_SPIN: 'spin_button',
      EBT_STOP: 'stop_button',
    };
    this._eEventTypes =  {
      SPIN_CLICK: this.getEventName(this._eButtonsTypes.EBT_SPIN),
      STOP_CLICK: this.getEventName(this._eButtonsTypes.EBT_STOP),
    };
    this._eElementsTypes = {
      EET_SPIN_ARROW: 'spin_button_arrow',
      EET_FREESPINS_LABEL: 'free_spins_label',
      EET_FREESPINS_LABEL_TEXT: 'free_spins_label_text',
      EET_SPIN_BUTTON: this._eButtonsTypes.EBT_SPIN,
      EET_STOP_BUTTON: this._eButtonsTypes.EBT_STOP,
    };

    this.init(container);
    this.hideFreespins();
  }

  showFreespins(count, text) {
    if (count) this.freespinsCount = count;
    if (text) this.freespinsText = text;
    this.interactiveElements[this._eElementsTypes.EET_FREESPINS_LABEL].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.add('freespins_count_shown');
  }

  hideFreespins() {
    this.interactiveElements[this._eElementsTypes.EET_FREESPINS_LABEL].classList.add('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.remove('freespins_count_shown');
  }

  showSpinButton() {
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_STOP_BUTTON].classList.add('hidden');
  }

  showStopButton() {
    this.interactiveElements[this._eElementsTypes.EET_STOP_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].classList.add('hidden');
  }

  set freespinsCount(count) {
    this.interactiveElements[this._eElementsTypes.EET_SPIN_BUTTON].setAttribute('data-freespins', count);
  }

  set freespinsText(text) {
    this.set(this._eElementsTypes.EET_FREESPINS_LABEL_TEXT, text);
  }

  _getMarkup() {
    return `<div class="ui-container__control_buttons_container__stop_spin_container">
        <!--    spin    -->
        <div>
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_SPIN_BUTTON]} class="ui-container__control_buttons_container__stop_spin_container__spin" data-color="green" data-freespins="0">
            <svg id=${this.interactiveElementsIds[this._eElementsTypes.EET_SPIN_ARROW]} width="110" height="111" viewBox="0 0 110 111" xmlns="http://www.w3.org/2000/svg">
              <path fill="#ffffff" stroke="none" d="M59 16C40.699 16.002 24.2318 23.2492 15.3233 40C1.5598 65.8796 17.5217 102.552 48 105.826C71.9546 108.4 95.4771 95.1237 99.7145 70C101.233 60.9954 101.575 39.0951 90.8156 34.7577C86.4036 32.9791 82.5993 36.5304 83.5424 41.0039C85.0996 48.3898 89.9923 53.9094 89.74 62C89.1247 81.728 70.3197 97.3915 51 94.9815C26.7027 91.9506 13.81 63.2003 26.966 43C34.8319 30.9224 47.3652 27.0015 61 27C59.4676 28.2106 57.7459 29.3698 56.3735 30.7693C53.0968 34.1104 53.1292 40.5659 59.0185 40.7184C64.8242 40.8687 79.8475 30.3363 79.3418 23.9992C78.9753 19.4063 73.9924 15.2178 71.1551 12C68.6544 9.16395 66.0357 5.05228 62.0039 4.37654C54.0517 3.04377 56.5611 12.3043 59 16z"/>
            </svg>
            <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_FREESPINS_LABEL]}>
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_FREESPINS_LABEL_TEXT]}>
                FREESPINS
              </span>
            </div>
          </button>
        </div>
        <!--    stop    -->
        <div>
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_STOP_BUTTON]} class="ui-container__control_buttons_container__stop_spin_container__stop" data-color="cancel">
            <div></div>
          </button>
        </div>
      </div>`
  }
}
