import { getUrlParam } from '../utils/url';
import { errorTypes } from '../utils/errorTypes';
// import LocalizationManager from '../i18n/LocalizationManager';

export class ManagerRest {
  constructor() {
    this.api = getUrlParam('api');
    this.sid = getUrlParam('sid');
    this.gid = getUrlParam('gid');
    window.onunload = this.closeGame.bind(this);
  }

  async getWrapperInfo() {
    const sessionHash = getUrlParam('sid');
    const sessionId = getUrlParam('gid');
    const api = getUrlParam('api');
    // url = `http://api.onlyplay.development/api/${url}`;
    // const url = `https://${api}/internal/wrapperInfo`;
    const url = 'https://api.dev.onlyplay.net/internal/wrapperInfo';
    const formData = new FormData();
    formData.append('sid', sessionHash);
    formData.append('gid', sessionId);

    const response = await this.postRequest(url, formData);

    if (!response) return console.warn(`Can't reach wrapper server`);

    if (response.success) {
      console.log('Wrapper info: ', response);
      // await LocalizationManager.changeLocalization(response.lang);
      return response.data
    }
  };

  async checkSessionStatus(inactivity_timeout) {
    const res = await this.postRequest(
      `https://${this.api}/check_session_status`,
      JSON.stringify({
        sid: this.sid,
        gid: this.gid,
        inactivity_timeout,
      })
    );
    if (res && res.status === 'closed') {
      window.OPWrapperService.showError(errorTypes.SESSION_EXPIRED.CODE, res.message)
    }
    return res;
  }

  async closeGame() {
    const data = {
      sid: this.sid,
      gid: this.gid,
      gameTitle: document.title,
      statistic: window.OPWrapperService.ControllerStatistic.statistic
    }
    window.OPWrapperService.eventManager.dispatch(
      window.OPWrapperService.eventManager.postMessageTypes.PMT_CLOSED,
      data
    );
      navigator.sendBeacon(
        `https://${this.api}/game_closed`,
        JSON.stringify(data)
        );
      return false;
  }

  async postRequest (url, data) {
    try {
      const rawResponse = await fetch(url, {
        method: 'post',
        body: data,
      });

      if (rawResponse.status === 500) {
        throw new Error('Server Down');
      }

      const response = await rawResponse.json()
        .catch((error) => {
          console.error(error);
          console.error('Response', response);
          throw error;
        });

      if (!response.success) {
        window.OPWrapperService.showError(response.code, response.message);
        throw response;
      }

      return response;
    } catch (e) {
      console.error(e);
    }
  };

}
