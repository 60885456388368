import './styles/index.scss';
import LandscapeUi from './uiGroups/landscapeUi';
import PortraitUi from './uiGroups/portraitUi';

import InterWoff2 from '../assets/fonts/Inter/Inter-Bold.woff2'
import InterWoff from '../assets/fonts/Inter/Inter-Bold.woff'
import InterTtf from '../assets/fonts/Inter/Inter-Bold.ttf'
import { preloadFontFace } from '../utils/preloadFontFace';

preloadFontFace('Inter', [InterWoff2, InterWoff, InterTtf], 700);

/**
 * UI config example
 @param config = {
  H: {
    init_controls: Boolean
    colors: {
       primary: {
        default: '#000',
        hover: '#000',
        pressed: '#000',
       },
       secondary: {
       ...
       },
       spin: {
       ...
       },
       cancel: {
       ...
       }
       toggled: {
        default: '#000',
       },
       panel: {
        default: '#000'
       }
       overlay: {
        default: '#000'
       }
    },
    offsets: {
      right_panel: { top: 0, left: 0 },
      bottom_panel: { top: 0, left: 0 },
    },
  },

  V: {
    init_controls: Boolean
    colors: ...,
    offsets: {
      top: 0,
      left: 0,
    },
  }
};
 */

export default class ControllerUi {
  constructor({ config, partnerConfig }) {
    this._config = config;
    this._partnerConfig = partnerConfig;
    this._currentOrientation = '';
    this._ui = null;

    this._initializingAdapter = {
      'H': LandscapeUi,
      'V': PortraitUi,
    };

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._onResize, this
    );
  }

  init({ scaleData }) {
    const { orientation } = scaleData;

    if (!orientation || !this._initializingAdapter.hasOwnProperty(orientation)) throw new Error(`Unhandled orientation type: ${orientation}`);

    if (this._currentOrientation !== orientation) {
      this._currentOrientation = orientation;

      this._removeCurrentUi();
      const config = this._config && this._config[orientation];
      this._ui = new this._initializingAdapter[orientation]({ config, partnerConfig: this._partnerConfig });
    }

    this._ui.scaleData = scaleData;
    return this;
  }

  _onResize(scaleData) {
    this.init({ scaleData });
  }

  _removeCurrentUi() {
    if (this._ui) {
      this._ui.remove();
      this._ui = null;
    }
  }

  get hud() {
    return this._ui;
  }

  get currentOrientation() {
    return this._currentOrientation;
  }
}
