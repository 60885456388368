import LocalizationManager from '../i18n/LocalizationManager';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../utils/constants';

export default class BetSelector {
  constructor(container) {
    this._container = container;
    this._bottomBar = null;
    this._bets = [];
    this._currency = '';
    this._activeBetIndex = 0;
    this._betElements = [];
    this.inited = false;

    this.onBetIncrease = () => {};
    this.onBetDecrease = () => {};
    this.onBetMin = () => {};
    this.onBetMax = () => {};
    this.onChangeActiveBet = (index) => {};
    this.onStart = (index) => {};

    this._container.insertAdjacentHTML('beforeend', this._generateMarkup());

    this.label = document.getElementById('betSelector-label');
    this.startButton = document.getElementById('betSelector-confirm');
    LocalizationManager.addLocalizationChangedCallback(this._updateLocalization.bind(this));
  }

  init(bets, currency, activeBetIndex = 0) {
    if (this.inited) return;
    this.inited = true;

    this._bets = bets;
    this._currency = currency;
    this._activeBetIndex = activeBetIndex;
    this._bottomBar = document.getElementById('bottomBar');

    const betSelector = document.getElementById('betSelector');
    betSelector.insertAdjacentHTML('beforeend', this._generateBetSelectorItems());
    this.betSelectorItem = document.querySelector('.betSelector-item');

    const inreaseButton = document.getElementById('betSelector-increase');
    inreaseButton.addEventListener('touchstart', () => this._onBetIncrease(), false);

    const dereaseButton = document.getElementById('betSelector-decrease');
    dereaseButton.addEventListener('touchstart', () => this._onBetDecrease(), false);

    const minButton = document.getElementById('betSelector-min');
    minButton.addEventListener('touchstart', () => this._onBetMin(), false);

    const maxButton = document.getElementById('betSelector-max');
    maxButton.addEventListener('touchstart', () => this._onBetMax(), false);

    const closeButton = document.getElementById('betSelector-close');
    closeButton.addEventListener('touchstart', () => this.close(), false);

    this.startButton.addEventListener('touchstart', () => this._onStart(), false);

    this._betElements = Array.from(betSelector.children);
    this._changeActiveBet(this._activeBetIndex);
  }

  show() {
    this._bottomBar.style.display = 'block'
    this._bottomBar.classList.remove('hideBottomBar');
    this._bottomBar.classList.add('showBottomBar');
  }

  close() {
    this._bottomBar.classList.remove('showBottomBar')
    this._bottomBar.classList.add('hideBottomBar');
    window.OPWrapperService.initConfig.onButtonClick();
    this.onStart(this._activeBetIndex);
  }

  toggle() {
    this._bottomBar.classList.contains('hideBottomBar') ? this.show() : this.close();
  }

  _onBetIncrease() {
    this._activeBetIndex += 1;
    this._activeBetIndex = this._activeBetIndex >= this._bets.length ? this._bets.length - 1 : this._activeBetIndex;
    this._changeActiveBet(this._activeBetIndex);
    window.OPWrapperService.initConfig.onButtonClick();
    this.onBetIncrease();
  }

  _onBetDecrease() {
    this._activeBetIndex -= 1;
    this._activeBetIndex = this._activeBetIndex <= 0 ? 0 : this._activeBetIndex;
    this._changeActiveBet(this._activeBetIndex);
    window.OPWrapperService.initConfig.onButtonClick();
    this.onBetDecrease();
  }

  _onBetMin() {
    this._activeBetIndex = 0;
    this._changeActiveBet(this._activeBetIndex);
    window.OPWrapperService.initConfig.onButtonClick();
    this.onBetMin();
  }

  _onBetMax() {
    this._activeBetIndex = this._bets.length - 1;
    this._changeActiveBet(this._activeBetIndex);
    window.OPWrapperService.initConfig.onButtonClick();
    this.onBetMax();
}

  _onStart() {
    this.close();
    this.onStart(this._activeBetIndex);
  }

  changeActiveBet(index) {
    if (!this.inited || index === this._activeBetIndex) return;
    this._activeBetIndex = index;
    this._changeActiveBet(index);
  }

  _changeActiveBet(index) {
    const bet = this._bets[this._activeBetIndex];
    this.betSelectorItem.textContent = `${bet} ${this._currency}`
    this.onChangeActiveBet(index);
  }

  // TODO: Remove to helpers
  _clamp(original, min, max) {
    return Math.min(Math.max(original, min), max);
  };

  _generateBetSelectorItems() {
    const bet = this._bets[this._activeBetIndex];
    return `<div class="betSelector-item" data-index=${this._activeBetIndex}> ${bet} ${this._currency} </div>`
  }

  _setupButton(id, className, text) {
    return (
      `
        <div class="bottomBar-column">
            <button class="button ${className}" title="${text}" id=${id}>
                <span class="text" title="${text}">${text}</span>
            </button>
        </div>
      `
    );
  }

  _updateLocalization() {
    this.label.innerText = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.betSelector.label`);
    this.startButton.title = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.betSelector.confirm`);
    this.startButton.children[0].title = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.betSelector.confirm`);
    this.startButton.children[0].innerText = LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.betSelector.confirm`);
  }

  _generateMarkup() {
    return (
      `
        <div class="bottomBar hideBottomBar" id="bottomBar" style="display: none">
          ${this._setupButton('betSelector-close', 'close', '')}
          <div class="bottomBar-row mt-1">
            <div class="bottomBar-column">
              <h4 id="betSelector-label" class="bottomBar-headline">${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.betSelector.label`)}</h4>                    
              <div class="bottomBar-row align-items-center">
                ${this._setupButton('betSelector-min', 'min', '')}                    
                ${this._setupButton('betSelector-decrease', 'minus', '')}                    
                <div class="bottomBar-column">
                    <div class="betSelector" id="betSelector"></div>
                </div>                     
                ${this._setupButton('betSelector-increase', 'plus', '')}
                ${this._setupButton('betSelector-max', 'max', '')}  
              </div>
              ${this._setupButton('betSelector-confirm', 'confirm', LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.betSelector.confirm`))}
            </div>
          </div>
        </div>
      `
    )
  }
}
