import EventEmitter from '../eventEmitter';
import { format } from '../../utils/formatCurrency';

export default class BaseController extends EventEmitter {
  constructor(type) {
    super();
    this._type = type;
    this._defaultCurrencyDecimal = 2;
    this._eElementsTypes = null;
    this._eButtonsTypes = null;
    this._eButtonsActions = null;
    this._eEventTypes = null;
    this._interactiveElementsIds = null;
    this._localizations = null;
    this._adaptiveElements = null;
    window.OPWrapperService.localizations.addLocalizationChangedCallback(this._updateLocalizations.bind(this));
  }

  init(container) {
    this._container = container;
    container.insertAdjacentHTML('afterbegin', this._getMarkup());
    this._saveInteractiveElements();
    this._setAdaptationListeners();
    this.addActions();
  }

  set(type, value) {
    if (!this._interactiveElements[type]) throw new Error(`Unhandled element type: ${type}`);
    this._interactiveElements[type].innerHTML = String(value);
  }

  addActions() {
    if (!this._eButtonsTypes) return;

    for (let key in this._eButtonsTypes) {
      const type = this._eButtonsTypes[key];
      const button = this._interactiveElements[type];
      button.addEventListener('click', (e) => {
        if (this._eButtonsActions && this._eButtonsActions.hasOwnProperty(type)) {
          this._eButtonsActions[type](e);
        }
        this.emit(this.getEventName(type));
      }, true);
    }
  }

  getEventName(buttonType) {
    return `${this.controllerType}__${buttonType}:click`
  }

  block(types) {
    const buttonTypes = this._getButtonTypesArray(types);
    buttonTypes.forEach(type => {
      const button = this._interactiveElements[type];
      button.setAttribute('disabled', '');
    });
  }

  unblock(types) {
    const buttonTypes = this._getButtonTypesArray(types);

    buttonTypes.forEach(type => {
      const button = this._interactiveElements[type];
      button.removeAttribute('disabled');
    });
  }

  isBlocked(type) {
    const button = this._interactiveElements[type];
    return button.getAttribute('disabled') !== null;
  }

  show() {
    this._container.classList.remove('hidden');
  }

  hide() {
    this._container.classList.add('hidden');
  }

  beforeRemove() {
  }

  _setAdaptationListeners() {
    if (!this._adaptiveElements) return;
    const resizeObserver = new ResizeObserver((mutations) => {
      mutations.forEach(mutation => {
          this._adaptFontSize(mutation.target);
      })
    });
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') this._adaptFontSize(mutation.target)
      })
    });

    for (const type of this._adaptiveElements) {
      resizeObserver.observe(this.interactiveElements[type]);
      mutationObserver.observe(this.interactiveElements[type], { childList: true, characterData: true });
    }
  }

  _updateLocalizations() {
    if (!this._localizations) return;
    for (let type in this._localizations) {
      this.set(type, this._getLocalization(this._localizations[type]));
    }
  }

  _getLocalization(key) {
    return window.OPWrapperService.localizations.getLocalizedText(key);
  }

  _getButtonTypesArray(types) {
    if (!Array.isArray(types)) {
      types = types ? [types] : [];
    }

    if (!types.length) {
      types = Object.values(this._eButtonsTypes);
    }

    return types;
  }

  _getMarkup() {
    throw new Error(`You must override 'getMarkup' method inside derived class`);
  }

  _saveInteractiveElements() {
    this._interactiveElements = {};
    for (let type in this.interactiveElementsIds) {
      const id = this.interactiveElementsIds[type];
      const element = document.getElementById(id);

      if (!element) throw new Error(`Can't find element by given id: '${id}'`);
      this._interactiveElements[type] = element;
    }
  }

  _adaptFontSize(el) {
    if (getComputedStyle(el).fontSize === '') return;
    // css overflow property must be specified on an element;
    if (!this._prefferedFontSizes) {
      this._prefferedFontSizes = new Map();
    }
    if (!this._prefferedFontSizes.has(el)) {
      this._prefferedFontSizes.set(el, parseFloat(getComputedStyle(el).fontSize));
    }

    while (el.offsetWidth === el.scrollWidth) {
      const fontSize = parseFloat(getComputedStyle(el).fontSize);
      if (fontSize === this._prefferedFontSizes.get(el)) break;
      el.style.fontSize = (fontSize + 1) + 'px';
    }
    while (el.offsetWidth < el.scrollWidth) {
      const fontSize = parseFloat(getComputedStyle(el).fontSize);
      el.style.fontSize = (fontSize - 1) + 'px';
    }
  }

  _moneyFormat(value) {
    return `${format(value, window.OPWrapperService.currencyInfo.decimals)} ${window.OPWrapperService.currencyInfo.currency.toUpperCase()}`
  }

  get interactiveElementsIds() {
    if (!this._interactiveElementsIds) {
      this._interactiveElementsIds = {};
      for (let key in this.elementsTypes) {
        const type = this.elementsTypes[key];
        this._interactiveElementsIds[type] = `ui__${this.controllerType}__${type}`;
      }
    }
    return this._interactiveElementsIds;
  }

  get elementsTypes() {
    return this._eElementsTypes;
  }

  get interactiveElements() {
    return this._interactiveElements;
  }

  get buttonsTypes() {
    return this._eButtonsTypes;
  }

  get events() {
    if (!this._eEventTypes) return;
    return { [this.controllerType]: this._eEventTypes }
  }

  get controllerType() {
    if (!this._type) {
      throw new Error(`You must specify controller type for '${this.constructor.name}'`);
    }
    return this._type;
  }

  set scaleData(data) {

  }
}
