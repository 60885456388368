export default new class Adapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_STOP_AUTO_SPINS, this.stopAutoSpins, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    // storeObserver.addListener(selectors.getBetAmount, this.stakeUpdate.bind(this));
    // storeObserver.addListener(selectors.getGameState, this.stateUpdated.bind(this));


    window.EMAdapter = this;
    // controllerSounds.onSoundSettingsCahnged.add(this.audioToggle.bind(this));
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  stopAutoSpins() {
    window.game.managerAutoplay.stopAutoplay();
  }

  setAudio(value) {
    window.game.managerSound.mute(!value);
  }

  showHelp() {

  }

  togglePaytable() {
    window.game.managerPopups.toggleInfoModal();
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, data);
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED);
  }

  audioToggle(value) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, value);
  }

  stakeUpdate(value) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE, value);
  }

  roundStart() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_START);
  }

  roundEnd() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_END);
  }

  closed() {

  }

  errorMessage(data) {
    const message = {
      errorCode: '8501',
      errorMessage: data.params.message
    };
    //EveryMatrix.errorMessage(message);
  }
}